<template>
  <transition name="modal-fade">
    <div style="z-index: 999" class="modal-backdrop" @click.prevent="close">
      <div style="z-index: 1000" class="modal" @click.stop>
        <header class="modal-header">
          <slot name="header"> Loading Title... </slot>
          <button type="button" class="btn-close" @click="close">x</button>
        </header>

        <section class="modal-body">
          <Spinner v-if="isLoading" />
          <slot name="body"> Loading Body... </slot>
        </section>
      </div>
      <div class="campaign-view">
        <div v-if="phoneView" class="campaign-details">
          <div
            class="campaign-image"
            :style="{
              'background-image': `url(${previewImage})`,
            }"
          ></div>
          <div class="campaign-text">
            <div class="campaign-title">{{ previewTitle }}</div>
            <div class="campaign-discount">
              Din rabatt:
              <b v-if="previewDiscount >= 0">{{ previewDiscount }} %</b>
            </div>
            <div class="campaign-description">
              {{ previewDesc }}
            </div>
            <div class="campaign-barcode">
              <div style="text-align: center">
                <!-- insert your custom barcode setting your data in the GET parameter "data" -->
                <img
                  v-if="previewBarcode"
                  alt="Barcode Generator TEC-IT"
                  :src="`https://barcode.tec-it.com/barcode.ashx?data=${previewBarcode}&code=EANUCC128&translate-esc=true`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div v-if="phoneView" class="modalPhoneView">
        <div id="iphone-x">
          <div class="iphonecontent">
            <div
              :class="{ imagePreviewPhone: previewImage }"
              :style="{ 'background-image': `url(${previewImage})` }"
              style="aspect-ratio: 2.5 / 1"
              @click="selectImage"
            ></div>
            <div class="previewTextArea">
              <h3>{{ previewTitle }}</h3>
              <b v-if="previewDiscount >= 0">{{ previewDiscount }}%</b>
              <p>{{ previewDesc }}</p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </transition>
</template>

<script>
import Spinner from './misc/Spinner.vue'

export default {
  components: {
    Spinner,
  },
  name: 'Modal',
  props: {
    phoneView: {
      type: Boolean,
      default: false,
    },
    previewImage: {
      type: String,
      default: '',
    },
    previewTitle: {
      type: String,
      default: '',
    },
    previewDiscount: {
      type: String,
      default: '',
    },
    previewDesc: {
      type: String,
      default: '',
    },
    previewBarcode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  mounted() {
    this.isLoading = false
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.modal-backdrop {
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  z-index: 999;
  margin-left: 5%;
  padding: 20px;
  position: relative;
  width: 40%;
  height: 80%;
  background: #ffffff;
  box-shadow: 2px 2px 30px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  border-radius: 0.4%;
}
.modalPhoneView {
  position: relative;
  justify-content: center;
  left: 21%;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: rgb(32, 183, 248);
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: rgb(73, 73, 73);
  background: transparent;
}

.btn-green {
  color: white;
  background: rgb(32, 183, 248);
  border: 1px solid rgb(32, 183, 248);
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.campaign-view {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0;
  height: 678px;
  width: 370px;
  padding: 10px;
  background-color: transparent;
}
.campaign-details {
  background-color: transparent;
  height: 80%;
  box-shadow: 1px 1px 5px 4px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 1px 1px 5px 4px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 1px 1px 5px 4px rgba(0, 0, 0, 0.06);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #f5f5f5;
}
.campaign-image {
  width: 100%;
  height: 50%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-image: url('../assets/logo.png');
  aspect-ratio: 3 / 2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.campaign-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  background-color: white;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 20px;
}
.campaign-title {
  font-weight: 900;
  font-size: 18px;
  height: 10%;
}
.campaign-discount {
  font-weight: 900;
  font-size: 14px;
  padding-top: 10px;
  height: 10%;
}
.campaign-description {
  font-size: 12px;
  padding-top: 10px;
  height: 55%;
}
.campaign-barcode {
  margin: 0 auto;
  justify-content: flex-end;
  height: 5%;
}
.campaign-barcode img {
  width: 125px;
  height: 50px;
}
</style>
